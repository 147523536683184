.has-switch {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid;
    border-color: #e3e3e3;
    position: relative;
    text-align: left;
    overflow: hidden;
    line-height: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    vertical-align: middle;
    min-width: 100px;
}

.has-switch.switch-mini {
    min-width: 72px;
}

.has-switch.switch-mini i.switch-mini-icons {
    height: 1.20em;
    line-height: 9px;
    vertical-align: text-top;
    text-align: center;
    transform: scale(0.6);
    margin-top: -1px;
    margin-bottom: -1px;
}

.has-switch.switch-small {
    min-width: 80px;
}

.has-switch.switch-large {
    min-width: 120px;
}

.has-switch.deactivate {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}

.has-switch.deactivate label,
.has-switch.deactivate span {
    cursor: default !important;
}

.has-switch > div {
    display: inline-block;
    width: 150%;
    position: relative;
    top: 0;
}

.has-switch > div.switch-animate {
    -webkit-transition: left 0.5s;
    -moz-transition: left 0.5s;
    -o-transition: left 0.5s;
    transition: left 0.5s;
}

.has-switch > div.switch-off {
    left: -50%;
    margin-left: 1px !important;
 /* parent container with float:left issue fix*/;
}

.has-switch > div.switch-on {
    left: 0%;
}

.has-switch input[type=radio],
.has-switch input[type=checkbox] {
    display: none;
}

.has-switch span,
.has-switch label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 14px;
    line-height: 20px;
}

.has-switch span.switch-mini,
.has-switch label.switch-mini {
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 10px;
    line-height: 9px;
}

.has-switch span.switch-small,
.has-switch label.switch-small {
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 12px;
    line-height: 18px;
}

.has-switch span.switch-large,
.has-switch label.switch-large {
    padding-bottom: 9px;
    padding-top: 9px;
    font-size: 16px;
    line-height: normal;
}

.has-switch label {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    width: 34%;
    color: #ffffff;
}

.has-switch label:hover,
.has-switch label:focus,
.has-switch label:active,
.has-switch label.active,
.has-switch label.disabled,
.has-switch label[disabled] {
    color: #ffffff;
    background-color: #e6e6e6;
}

.has-switch label:active,
.has-switch label.active {
    background-color: #cccccc \9;
}

.has-switch label i {
    color: #666;
    line-height: 18px;
    pointer-events: none;
}

.has-switch span {
    text-align: center;
    z-index: 1;
    width: 33%;
}

.has-switch span.switch-left {
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
}

.has-switch span.switch-right {
    color: #333333;
    background-color: #e0e0e0;
}

.has-switch span.switch-right:hover,
.has-switch span.switch-right:focus,
.has-switch span.switch-right:active,
.has-switch span.switch-right.active,
.has-switch span.switch-right.disabled,
.has-switch span.switch-right[disabled] {
    color: #333333;
    background-color: #e9e9e9;
}

.has-switch span.switch-right:active,
.has-switch span.switch-right.active {
    background-color: #e6e6e6 \9;
}

.has-switch span.switch-primary,
.has-switch span.switch-left {
    color: #ffffff;
    background-color: #428bca;
}

.has-switch span.switch-primary:hover,
.has-switch span.switch-left:hover,
.has-switch span.switch-primary:focus,
.has-switch span.switch-left:focus,
.has-switch span.switch-primary:active,
.has-switch span.switch-left:active,
.has-switch span.switch-primary.active,
.has-switch span.switch-left.active,
.has-switch span.switch-primary.disabled,
.has-switch span.switch-left.disabled,
.has-switch span.switch-primary[disabled],
.has-switch span.switch-left[disabled] {
    color: #ffffff;
    background-color: #68AAE3;
}

.has-switch span.switch-primary:active,
.has-switch span.switch-left:active,
.has-switch span.switch-primary.active,
.has-switch span.switch-left.active {
    background-color: #4B9ADE \9;
}

.has-switch span.switch-info {
    color: #ffffff;
    background-color: #41a7c5;
}

.has-switch span.switch-info:hover,
.has-switch span.switch-info:focus,
.has-switch span.switch-info:active,
.has-switch span.switch-info.active,
.has-switch span.switch-info.disabled,
.has-switch span.switch-info[disabled] {
    color: #ffffff;
    background-color: #5bc0de;
}

.has-switch span.switch-info:active,
.has-switch span.switch-info.active {
    background-color: #31b0d5 \9;
}

.has-switch span.switch-success {
    color: #ffffff;
    background-color: #3cc051;
}

.has-switch span.switch-success:hover,
.has-switch span.switch-success:focus,
.has-switch span.switch-success:active,
.has-switch span.switch-success.active,
.has-switch span.switch-success.disabled,
.has-switch span.switch-success[disabled] {
    color: #ffffff;
    background-color: #62c462;
}

.has-switch span.switch-success:active,
.has-switch span.switch-success.active {
    background-color: #42b142 \9;
}

.has-switch span.switch-warning {
    color: #ffffff;
    background-color: #f9a123;
    border-color: #fbb450;
}

.has-switch span.switch-warning:hover,
.has-switch span.switch-warning:focus,
.has-switch span.switch-warning:active,
.has-switch span.switch-warning.active,
.has-switch span.switch-warning.disabled,
.has-switch span.switch-warning[disabled] {
    color: #ffffff;
    background-color: #fbb450;
}

.has-switch span.switch-warning:active,
.has-switch span.switch-warning.active {
    background-color: #fa9f1e \9;
}

.has-switch span.switch-danger {
    color: #ffffff;
    background-color: #d14641;
    border-color: #ee5f5b #ee5f5b #e51d18;
}

.has-switch span.switch-danger:hover,
.has-switch span.switch-danger:focus,
.has-switch span.switch-danger:active,
.has-switch span.switch-danger.active,
.has-switch span.switch-danger.disabled,
.has-switch span.switch-danger[disabled] {
    color: #ffffff;
    background-color: #ee5f5b;
}

.has-switch span.switch-danger:active,
.has-switch span.switch-danger.active {
    background-color: #e9322d \9;
}

.has-switch span.switch-default {
    color: #333333;
    background-color: #f0f0f0;
}

.has-switch span.switch-default:hover,
.has-switch span.switch-default:focus,
.has-switch span.switch-default:active,
.has-switch span.switch-default.active,
.has-switch span.switch-default.disabled,
.has-switch span.switch-default[disabled] {
    color: #333333;
    background-color: #ffffff;
}

.has-switch span.switch-default:active,
.has-switch span.switch-default.active {
    background-color: #e6e6e6 \9;
}
