.bootstrap-select.btn-group,.bootstrap-select.btn-group[class*="span"] {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 0;
}

.form-search .bootstrap-select.btn-group,.form-inline .bootstrap-select.btn-group,.form-horizontal .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.bootstrap-select.form-control {
    padding: 0;
    border: 0;
}

.bootstrap-select.btn-group.pull-right,.bootstrap-select.btn-group[class*="span"].pull-right,.row-fluid .bootstrap-select.btn-group[class*="span"].pull-right {
    float: right;
}

.input-append .bootstrap-select.btn-group {
    margin-left: -1px;
}

.input-prepend .bootstrap-select.btn-group {
    margin-right: -1px;
}

.bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]) {
    width: 220px;
}

.bootstrap-select {
    width: 220px\0;
}

.bootstrap-select.form-control:not([class*="span"]) {
    width: 100%;
}

.bootstrap-select>.btn {
    width: 100%;
}

.error .bootstrap-select .btn {
    border: 1px solid #b94a48;
}

.dropdown-menu {
    z-index: 2000;
}

.bootstrap-select.show-menu-arrow.open>.btn {
    z-index: 2051;
}

.bootstrap-select .btn:focus {
    outline: thin dotted #333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
    overflow: hidden;
    position: absolute;
    left: 12px;
    right: 25px;
    text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}

.bootstrap-select.btn-group>.disabled,.bootstrap-select.btn-group .dropdown-menu li.disabled>a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group>.disabled:focus {
    outline: none !important;
}

.bootstrap-select.btn-group[class*="span"] .btn {
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    min-width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    position: static;
    border: 0;
    padding: 0;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu dt {
    display: block;
    padding: 3px 20px;
    cursor: default;
}

.bootstrap-select.btn-group .div-contain {
    overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-menu li {
    position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li>a.opt {
    position: relative;
    padding-left: 35px;
}

.bootstrap-select.btn-group .dropdown-menu li>a {
    cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
    font-weight: normal;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark {
    display: inline-block;
    position: absolute;
    right: 15px;
    margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
    display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: .5em;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:hover small,.bootstrap-select.btn-group .dropdown-menu li:not(.disabled)>a:focus small {
    color: #64b1d8;
    color: rgba(255,255,255,0.4);
}

.bootstrap-select.btn-group .dropdown-menu li>dt small {
    font-weight: normal;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #CCC;
    border-bottom-color: rgba(0,0,0,0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
    bottom: auto;
    top: -3px;
    border-top: 7px solid #ccc;
    border-bottom: 0;
    border-top-color: rgba(0,0,0,0.2);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
    bottom: auto;
    top: -3px;
    border-top: 6px solid #fff;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before,.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after {
    display: block;
}

.mobile-device {
    position: absolute;
    top: 0;
    left: 0;
    display: block !important;
    width: 100%;
    height: 100% !important;
    opacity: 0;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
    position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.control-group.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select-searchbox {
    padding: 4px 8px;
}
