.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.prettycheckbox,
.prettyradio {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.prettycheckbox a,
.prettyradio a {
    width: 30px;
    height: 30px;
    background: url('../images/prettyCheckable-blue.png') top left no-repeat;
    display: block;
    float: left;
    cursor: pointer;
    margin: 0;
}

.prettycheckbox.green a,
.prettyradio.green a {
    background-image: url('../images/prettyCheckable-green.png');
}

.prettycheckbox.red a,
.prettyradio.red a {
    background-image: url('../images/prettyCheckable-red.png');
}

.prettycheckbox.yellow a,
.prettyradio.yellow a {
    background-image: url('../images/prettyCheckable-yellow.png');
}

.prettycheckbox.gray a,
.prettyradio.gray a {
    background-image: url('../images/prettyCheckable-gray.png');
}

.prettyradio a {
    background-position: 0 -120px;
}

.prettycheckbox a:focus,
.prettyradio a:focus {
    outline: 0 none;
}

.prettycheckbox a:focus {
    background-position: 0 -30px;
}

.prettyradio a:focus {
    background-position: 0 -150px;
}

.prettycheckbox a.checked {
    background-position: 0 -60px;
}

.prettyradio a.checked {
    background-position: 0 -180px;
}

.prettycheckbox a.checked:focus {
    background-position: 0 -90px;
}

.prettyradio a.checked:focus {
    background-position: 0 -210px;
}

.prettycheckbox label,
.prettyradio label {
    display: block;
    float: left;
    margin: 6px 5px;
    cursor: pointer;
}

.prettycheckbox.disabled a,
.prettyradio.disabled a,
.prettycheckbox.disabled label,
.prettyradio.disabled label {
    cursor: not-allowed;
}

.prettycheckbox.disabled a {
    background-position: 0 -240px;
}

.prettyradio.disabled a {
    background-position: 0 -270px;
}
