
.dataTables_header,
.dataTables_footer {
    border: 1px solid #EAEEEA;
    float: left;
    width: 100%;
    background: #FFF;
}

.dataTables_header label,
.dataTables_footer label {
    margin: 0;
}

.dataTables_header input[type=text],
.dataTables_header select {
    background: #FFFFFF;
    border-color: #CCC #DDD #DDD #CCC;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
}

/* Header Dropdown */
div.dataTables_length {
    float: left;
    margin: 12px 10px 10px 10px;
}

div.dataTables_length label {
    float: left;
    font-weight: normal;
    text-align: left;

    text-transform: capitalize;
    font-size: 12px;
}

div.dataTables_length select {
    width: 65px;
    padding: 2px 4px;
    margin-right: 10px;
}

div.dataTables_length .bootstrap-select {
    width: 75px !important;
    margin-right: 5px;
}

div.dataTables_length .bootstrap-select .btn {
    padding: 3px;
}

/* Processing Message */
.dataTables_processing {
    margin: 13px 10px 0 30px;
    float: left;
    color: #008000;
}

/* Header Search */
div.dataTables_filter {
    float: right;
    margin: 10px;
}

div.dataTables_filter label {
    float: right;
    font-weight: normal;
}

div.dataTables_filter input {
    padding: 4px;
    margin-left:10px;
    min-width:200px;
}

/* Footer Info */
div.dataTables_info {
    float: left;
    margin: 13px 10px 0 10px;
}

/* Footer Pagination */
div.dataTables_paginate {
    float: right;
    margin: 5px 10px 0 10px;
}

div.dataTables_paginate ul.pagination {
    margin: 2px;
}

    /* Table Style */
table.table td.actions {
    text-align: right;
}

table.table {
    clear: both;
    margin: 0;

    thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
            cursor: pointer;
        }

        .sorting {
            background: url('/packages/netmatters/base/images/sort_both.png') no-repeat center right;
        }
        .sorting_asc {
            background: url('/packages/netmatters/base/images/sort_asc.png') no-repeat center right #eee;
            border:1px solid #DDD !important;
        }
        .sorting_desc {
            background: url('/packages/netmatters/base/images/sort_desc.png') no-repeat center right #eee;
            border:1px solid #DDD !important;
        }
        .sorting_asc_disabled {
            background: url('/packages/netmatters/base/images/sort_asc_disabled.png') no-repeat center right;
        }
        .sorting_desc_disabled {
            background: url('/packages/netmatters/base/images/sort_desc_disabled.png') no-repeat center right;
        }
    }

    th,
    td {
        border: 1px solid #EAEFF0;
    }

}

table.dataTable th:active {
    outline: none;
}



/* Scrolling */
div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    border-top: none;
}


/* TableTools styles*/
.table tbody tr:hover td {
    background-color: #C8F7C5 !important;
}

.table tbody tr.active td,
.table tbody tr.active th {
    background-color: #08C;
    color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
    background-color: #0075b0 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
    cursor: pointer;
}

div.DTTT .btn {
    color: #333 !important;
    font-size: 12px;
}

div.DTTT .btn:hover {
    text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu {
    z-index: 2003;
}

ul.DTTT_dropdown.dropdown-menu a {
    color: #333 !important; /* needed only when demo_page.css is included */
}

ul.DTTT_dropdown.dropdown-menu li {
    position: relative;
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
    background-color: #0088cc;
    color: white !important;
}

div.DTTT_collection_background {
    z-index: 2002;
}

/* TableTools information display */
div.DTTT_print_info.modal {
    height: 150px;
    margin-top: -75px;
    text-align: center;
}

div.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

div.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

/* FixedColumns styles */
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
    background-color: white;
}

div.DTFC_RightHeadWrapper table,
div.DTFC_LeftHeadWrapper table {
    margin-bottom: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
    border-top: none;
    margin-bottom: 0 !important;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
    border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
    border-top: none;
}

/* Table body sorting */
table.table tbody tr td.sorting_1 {
    background: #eee;
    border:1px solid #DDD !important;
}
